<template>
  <card class="pb-5">
    <template slot="header">
      <button class="btn btn-info" @click="handleEdit()">
        <i class="fa fa-step-backward"></i> ย้อนกลับ
      </button>
      <h4 class="card-title mt-4 ml-4">เพิ่มข้อมูล QR-Code</h4>
    </template>
    <form class="form-horizontal">
      <div class="row justify-content-center">
        <fg-input
          label="ลิ้งเว็บไซต์"
          type="text"
          v-model="link_name"
          style="width:60%"
        />
        <l-button
          v-if="!result"
          type="success"
          @click="genqrcode()"
          style="height:40px;margin-top:25px"
        >
          สร้าง QR-code</l-button
        >
      </div>
    </form>
    <div v-if="result" class="text-center">
      <qrcode-vue
        class="mt-4"
        :value="link_name"
        :size="size"
        level="H"
        id="imageDIV"
      ></qrcode-vue>
      <l-button type="success" @click="saveimage()" class="mt-4"
        >บันทึกรูป QR-Code</l-button
      >
      <l-button type="danger" @click="reset_url()" class="mt-4"
        >รีเซ็ต</l-button
      >
    </div>
  </card>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import $ from "jquery";
import html2canvas from "html2canvas";
import { mdbBtn } from "mdbvue";
var jwt = require("jsonwebtoken");
export default {
  data() {
    return {
      size: 250,
      link_name: "",
      result: false
    };
  },
  components: {
    QrcodeVue,
    mdbBtn
  },
  methods: {
    saveimage() {
      var canvas = document.getElementById("imageDIV");
      var dataURL = canvas.childNodes[0].toDataURL("image/png", 1.0);
      var a = document.createElement("a");
      a.href = dataURL;
      a.download = "qrpay_.png";
      a.click();
    },
    async genqrcode() {
      let user = JSON.parse(localStorage.getItem("user"));
      let result = JSON.parse(localStorage.getItem("user"));
      let username = jwt.verify(result, "ABAsecretKeywweqwlinkmanager");
      if (this.link_name.length > 0) {
        let payload = {
          url_web: this.link_name,
          createBy: username.result[0].Name
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/qrcode", { token: token }, { headers: headers })
          .then(async res => {
            if (res.data.code === 0) {
              this.result = true;
            } else {
              this.result = false;
            }
          });
      }
    },
    handleEdit() {
      this.$router.push("/qr_code");
    },
    reset_url() {
      this.result = false;
      this.link_name = null;
    }
  }
};
</script>
