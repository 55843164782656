<template>
  <router-view></router-view>
</template>

<script>
import NotificationSample from "src/pages/NotificationSample";
export default {
  mounted() {
    // this.$notify(
    //   {
    //     component: NotificationSample,
    //     icon: 'nc-icon nc-app'
    //   })
  }
};
</script>
