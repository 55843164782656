<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">รายละเอียดสมาชิก</h4>
        </template>
        <div>
          <form class="form-horizontal" @submit.prevent="handleSave()">
            <div class="row">
              <div class="col-md-3">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="result_data.createdAt"
                  disabled
                />
              </div>
              <div class="col-md-3">
                <fg-input
                  label="รหัสสมาชิก"
                  type="text"
                  disabled
                  :value="result_data.Code"
                />
              </div>
              <div class="col-md-3">
                <fg-input
                  label="ชื่อสมาชิก"
                  type="text"
                  disabled
                  :value="result_data.Name"
                />
              </div>
              <div class="col-md-3">
                <label> ตำแหน่ง</label><br />
                <el-select
                  label="แพลตฟอร์ม"
                  class="select-primary"
                  size="large"
                  :placeholder="result_data.DeptName"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <fg-input
                  label="เบอร์โทรศัพท์"
                  type="text"
                  :value="result_data.Phone_number"
                  disabled
                />
              </div>
              <div class="col-md-6">
                <fg-input
                  class="font-text"
                  label="ลิ้งสมัครสมาชิก"
                  type="text"
                  :value="result_data.link_register"
                  disabled
                />
              </div>
              <div class="col-md-3">
                <label> สิทธิ์การมองเห็นข้อมูล</label><br />

                <el-select
                  label="สิทธิ์การมองเห็น"
                  class="select-primary"
                  size="large"
                  :placeholder="roleName"
                  v-model="selectsRole.simple"
                >
                  <el-option
                    v-for="option in selectsRole.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label> สิทธิ์การมองเห็นเว็บ</label><br />
                <hr />
                <div class="form-check form-check-inline card-body">
                  <label class="form-check-label">
                    <input
                      v-model="checkAll"
                      @change="testCheckAll()"
                      class="form-check-input"
                      type="checkbox"
                      value="true"
                    />
                    <span class="form-check-sign"></span>
                    ทั้งหมด
                  </label>
                </div>

                <div
                  class="form-check form-check-inline card-body"
                  v-for="(item, index) in serviceAll"
                  :key="index"
                >
                  <label class="form-check-label">
                    <input
                      v-model="serviceSelect"
                      class="form-check-input"
                      type="checkbox"
                      :value="item"
                    />
                    <span class="form-check-sign"></span>
                    {{ item }}
                  </label>
                </div>
                <hr />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="submit" class="success btn btn-success" wide>
                  บันทึกข้อมูล</button
                >&nbsp;
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
  CheckboxGroup,
} from "element-ui";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2",
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true,
      },
      pieChart: {
        data: {
          labels: [],
          series: [],
        },
      },
      pieChart1: {
        data: {
          labels: ["ไม่มีข้อมูลสรุป"],
          series: [0],
        },
      },
      isChart: false,
      result_data: [],
      accept_url: "",
      click_url: "",
      platform_name: "",
      roleName: "",
      serviceName: [],
      serviceAll: [],
      serviceSelect: [],
      checkAll: false,
      selects: {
        simple: "",
        countries: [
          {
            label: "EMPLOYEE",
            value: "1",
          },
          {
            label: "ADMIN",
            value: "2",
          },
        ],
      },
      selectsRole: {
        simple: "",
        countries: [
          {
            label: "ทั้งหมด",
            value: "0",
          },
          {
            label: "เฉพาะของตัวเอง",
            value: "1",
          },
        ],
      },
      roleName: "",
    };
  },
  methods: {
    testCheckAll() {
      this.serviceSelect = [];
      if (this.checkAll) {
        this.serviceAll.forEach((element) => {
          this.serviceSelect.push(element);
        });
      } else {
        this.serviceSelect = [];
      }
    },
    handleEdit() {
      this.$router.push("/member");
    },
    async handleSave() {
      let payload = {};
      let serviceString = "";
      if (this.selects.simple == "") {
        this.selects.simple = "1";
        if (this.result_data.DeptName == "ADMIN") {
          this.selects.simple = "2";
        }
      }

      if (this.selectsRole.simple == "") {
        this.selectsRole.simple = 1;
        if (this.roleName == "ทั้งหมด") {
          this.selectsRole.simple = 0;
        }
      }

      this.serviceSelect.forEach((element) => {
        serviceString += element + ",";
      });

      serviceString = serviceString.substr(0, serviceString.length - 1);
      payload = {
        DeptOID: this.selects.simple,
        DeptName: this.selects.simple === "2" ? "ADMIN" : "EMPLOYEE",
        RoleOID: this.selects.simple,
        service: serviceString,
        readOnly: this.selectsRole.simple,
        Phone_number: this.result_data.Phone_number,
      };

      let user = JSON.parse(localStorage.getItem("user"));
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + user,
      };
      await this.$axios
        .post("/edit_member", { token: token }, { headers: headers })
        .then((res) => {
          if (res.data.code === 0) {
            this.getdata();
            Swal.fire({
              title: "แก้ไขสมาชิก สำเร็จ!",
              icon: "success",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: true,
            });
          }
        })
        .catch(() => {});
    },

    async getdata() {
      this.serviceAll = [];
      let user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        ObjectID: this.$route.query.id,
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + user,
      };
      await this.$axios
        .post(
          "/detail_member",
          {
            token: token,
          },
          { headers: headers }
        )
        .then(async (res) => {
          if (res.data.payload && this.$route.query.id) {
            this.result_data = res.data.payload;
            this.roleName = "ทั้งหมด";
            if (res.data.payload.read_only == 1) {
              this.roleName = "เฉพาะของตัวเองเท่านั้น";
            }
            this.serviceName = res.data.payload.service.split(",");
            if (this.serviceName[0].length == 0) {
              this.serviceName[0] = "ทั้งหมด";
            }

            this.serviceName.forEach((element) => {
              this.serviceSelect.push(element);
            });
            if (res.data.payload.read_only == 0) {
              this.roleName = "ทั้งหมด";
            }
          } else {
            this.$router.push("/member");
          }
        });
      this.serviceAll = [];
      await this.$axios
        .get("/list_serviceAll", { headers: headers })
        .then(async (res) => {
          if (res.data.code == 0) {
            for (const [index, item] of res.data.payload.entries()) {
              this.serviceAll.push(item.service_name);
            }
          } else {
            this.serviceAll = [];
          }

          if (this.serviceName[0] == "ทั้งหมด") {
            this.serviceSelect.shift();
            this.serviceAll.forEach((element) => {
              this.serviceSelect.push(element);
            });
          }
        });
    },
  },
  async mounted() {
    this.getdata();
  },
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
.font-text.label {
  color: black;
}
</style>
