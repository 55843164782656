import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";
import store from "./store";
import "mdbvue/lib/css/mdb.min.css"; //// css mdb
// router setup
import routes from "./routes/routes";
import VueClipboard from "vue-clipboard2";
import axios from "axios";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
import $ from "jquery";

axios.defaults.headers.common["cache-control"] = "no-cache";
if (process.env.NODE_ENV !== "development") {
  
  axios.defaults.baseURL = "https://affiliate.powerdiamonds.xyz/api";
} else {
  axios.defaults.baseURL = "http://localhost:2222/api";
}

Vue.use(VueClipboard);

Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$ = $;

// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);

// configure router
const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active"
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  template: '<qrcode-vue :value="value"></qrcode-vue>',
  components: {
    QrcodeVue
  },
  render: h => h(App)
}).$mount("#app");
