<template>
  <div class="row" style="justify-content: center">
    <div class="col-12">
      <card>
        <div>
          <div class="col-sm-12">
            <template>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-sm-12">
                  <l-button
                    class="mb-3 w-100 text-nowrap"
                    type="primary"
                    @click="handleadd()"
                    ><i class="fa  fa-plus-circle"></i> เพิ่มเว็บไซต์</l-button
                  >
                </div>
              </div>
              <card title="ข้อมูลเว็บไซต์ทั้งหมด">
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-select
                      class="select-default mb-3"
                      style="width: 200px"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-default"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <el-input
                      type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="ค้นหาเว็บไซต์ เครือ"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    />
                  </div>
                  <div class="col-sm-12">
                    <el-table
                      class="table-striped"
                      style="width: 100%;"
                      :data="queriedData"
                    >
                      <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                      >
                      </el-table-column>
                      <el-table-column :min-width="120" label="Actions">
                        <template slot-scope="props">
                          <button
                            class="btn btn-success mr-2"
                            style="padding: 8px;"
                            @click="handleView(props.$index, props.row)"
                          >
                            รายละเอียด
                          </button>
                          <button
                            class="btn btn-info "
                            style="padding: 8px;width: 80px;"
                            @click="handleEdit(props.$index, props.row)"
                          >
                            แก้ไข
                          </button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="">
                    <p class="card-category">
                      แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ
                    </p>
                  </div>
                  <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                  >
                  </l-pagination>
                </div>
              </card>
            </template>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { mdbBtn, mdbListGroupItem, mdbIcon } from "mdbvue";
import Fuse from "fuse.js";
var jwt = require("jsonwebtoken");
let no = 0;

export default {
  components: {
    mdbBtn,
    LPagination,
    mdbListGroupItem,
    mdbIcon,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result_selected = [];
      if (this.searchQuery !== "") {
        let result = this.tableData;
        result = this.fuseSearch.search(this.searchQuery);
        result.map(entry => {
          result_selected.push(entry.item);
        });
        this.tableData = result_selected;
        this.pagination.total = result.length;
        return result_selected.slice(this.from, this.to);
      } else {
        return this.users.slice(this.from, this.to);
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.users.length;
    }
  },
  data() {
    return {
      web: "",
      renderComponent: true,
      serviceName: null,
      search: "",
      activeItem: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100],
        total: 0
      },
      users: [],
      searchQuery: "",
      propsToSearch: ["service_name", "group"],
      tableColumns: [
        {
          prop: "createdAt",
          label: "วันที่",
          minWidth: 150
        },
        {
          prop: "service_name",
          label: "เว็บไซต์",
          minWidth: 150
        },
        {
          prop: "group",
          label: "เครือเว็บไซต์",
          minWidth: 150
        },
        {
          prop: "status",
          label: "สถานะ",
          minWidth: 150
        }
      ],
      tableData: [],
      fuseSearch: []
    };
  },
  watch: {
    async $route() {
      this.web = this.$route.query.web;
      let user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        web: this.$route.query.web
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + user
      };
      await this.$axios
        .post("/list_service", { token: token }, { headers: headers })
        .then(async res => {
          let result_data = [];
          if (res.data.payload[0]) {
            this.users = res.data.payload;
            this.tableData = res.data.payload;
          } else {
            this.users = [];
            this.tableData = [];
          }
        });
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["service_name", "group"]
      });
    }
  },
  methods: {
    handleView(index, row) {
      this.$router.push(
        "/detail_site?web=" + row.service_name + "&id=" + row.id
      );
    },
    handleEdit(index, row) {
      this.$router.push("/edit_site?web=" + row.service_name + "&id=" + row.id);
    },
    handleadd() {
      this.$router.push("/create_site");
    }
  },
  async mounted() {
    this.web = this.$route.query.web;
    let id_user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      ObjectID: id_user.ObjectID
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");

    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + id_user
    };
    await this.$axios
      .get("/list_service", { headers: headers })
      .then(async res => {
        let result_data = [];
        if (res.data.payload[0]) {
          for (const [index, item] of res.data.payload.entries()) {
            res.data.payload[index].status =
              item.status === 1 ? "ใช้งาน" : "ยกเลิก";
          }
          this.users = res.data.payload;
          this.tableData = res.data.payload;
        } else {
          this.users = [];
          this.tableData = [];
        }
      });
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["service_name"]
    });
  }
};
</script>
<style>
.row-center {
  display: -webkit-inline-box;
}
.btn-action {
  padding: 8px;
  margin-right: 5px;
}
.mdb-datatable-header-hover-icon {
  display: none;
  left: 0.1rem !important;
  top: 1.25rem !important;
}
.fas {
  font-weight: 900 !important;
  font-family: Font awesome\5 Free !important;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-up:before {
  color: black !important;
  display: table-column-group !important;
}
.fa-arrow-down:before {
  color: black !important;
  display: table-column-group !important;
}
.sorting {
  cursor: pointer !important;
  font-size: 15px !important;
  color: #000 !important;
}
.el-table thead {
  color: #333333;
  font-weight: 500;
}
.el-input {
  /* width: 200px !important; */
  max-width: 100%;
}
</style>
