<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-5 col-md-6 col-sm-12">
        <card class="text-center displaytext">
          <div class="col-md-12 block mtf">
            <h2><b>link management</b></h2>
          </div>
          <label class="text-center mb-2">Sign in With</label>
          <button
            type="buttin"
            @click="Login()"
            class="btn btn-success btn-block btn-lg fx-with"
          >
            <i class="fab fa-line"></i> Login
          </button>
        </card>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
var jwt = require("jsonwebtoken");

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLogin: "isLogin",
      user: "user",
    }),
  },
  methods: {
    ...mapActions({
      storeLogin: "login",
    }),
    Login() {
      let client_id = "1655252894";
      let state = Date.now();
      let redirect_uri = location.origin + "/login";
      let scope = "openid profile email";
      window.location.href =
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" +
        client_id +
        "&redirect_uri=" +
        redirect_uri +
        "&state=" +
        state +
        "&scope=" +
        scope;
    },

    submit() {
      alert("Form has been submitted!");
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  async mounted() {
    if (this.$route.query.code) {
      let payload = {
        code: this.$route.query.code,
        register: localStorage.getItem("code"),
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      this.$axios.post("/login", { token: token }).then(async (res) => {
        if (res.data.code == 0) {
          localStorage.setItem(
            "timeout",
            moment().add(20, "minutes").format("YYYYMMDDHHmm")
          );
          localStorage.setItem("user", JSON.stringify(res.data.payload));
          localStorage.setItem("isLogin", true);
          this.$router.push("/main");
        } else {
          Swal.fire({
            title: "ไม่มีสิทธ์เข้าใช้ระบบ",
            text: "กรุณาติดต่อผู้ดูแลระบบ",
            icon: "danger",
            showConfirmButton: false,
          });
        }
      });
    } else if (this.$route.query.register) {
      let payload = {
        code: this.$route.query.register,
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      await this.$axios
        .post("/check_register", { token: token })
        .then(async (res) => {
          if (res.data.code === 0) {
            localStorage.setItem("code", this.$route.query.register);
            let client_id = "1655252894";
            let state = Date.now();
            let redirect_uri = location.origin + "/login";
            let scope = "openid profile email";
            window.location.href =
              "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=" +
              client_id +
              "&redirect_uri=" +
              redirect_uri +
              "&state=" +
              state +
              "&scope=" +
              scope;
          } else {
            localStorage.clear();
            localStorage.removeItem("user");
            localStorage.removeItem("isLogin");
            Swal.fire({
              title: "ไม่มีสิทธ์ใช้งานระบบ",
              text: "กรุณาติดต่อผู้ดูแลระบบ",
              icon: "danger",
              showConfirmButton: false,
            });
          }
        });
    } else if (
      localStorage.getItem("isLogin") &&
      !this.$route.query.register &&
      !this.$route.query.code
    ) {
      this.$router.push("/main");
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.fx-with {
  margin-top: 10px !important;
  width: 70% !important;
  margin-left: 15% !important;
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.displaytext {
  margin-top: 38% !important;
}
.mtf {
  margin-top: -40px !important;
  font-variant: small-caps;
}
</style>
