<template>
  <footer class="footer">
    <div
      class="container-fluid d-flex flex-wrap justify-content-between"
      style="background-color: #00346b;"
    >
      <nav>
        <ul class="footer-menu">
          <li>
            <!-- <router-link :to="{ path: '/' }">Link-Management</router-link> -->
          </li>
        </ul>
      </nav>
      <div class="copyright" style="color: #fff;">
        &copy; 2020 Link-Management System
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
