<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">รายละเอียดแพลตฟอร์ม</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-4">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="result_data.createdAt"
                  disabled
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  label="แพลตฟอร์ม"
                  type="text"
                  :value="result_data.platform_name"
                  disabled
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  label="สถานะแพลตฟอร์ม"
                  type="text"
                  :value="result_data.status"
                  disabled
                />
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="หมายเหตุ"
                  type="text"
                  :value="result_data.comment"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-7" v-if="isChart">
                <div class="col-xl-12 col-md-12 mt-4">
                  <stats-card
                    :title="click_url"
                    subTitle="จำนวนคลิก"
                    class="col-md-6"
                  >
                    <div slot="header" class="icon-warning">
                      <i class="nc-icon nc-tap-01 text-warning"></i>
                    </div>
                    <template slot="footer">
                      <i class="fa fa-refresh"></i>Updated now :
                      {{ result_data.updatedAt }}
                    </template>
                  </stats-card>
                  <stats-card
                    :title="accept_url"
                    subTitle="จำนวนการตอบรับ"
                    class="col-md-6"
                  >
                    <div slot="header" class="icon-success">
                      <i class="nc-icon nc-vector text-success"></i>
                    </div>
                    <template slot="footer">
                      <i class="fa fa-refresh"></i>Updated now :
                      {{ result_data.updatedAt }}
                    </template>
                  </stats-card>
                </div>
              </div>
              <div class="col-md-4 text-center " v-if="isChart">
                <div v-if="!isnull">
                  <chart-card
                    :chart-data="pieChart.data"
                    class="text-center"
                    chart-type="Pie"
                  >
                    <template slot="header">
                      <h4 class="card-title">สรุปการดูโฆษณา</h4>
                      <p class="card-category"></p>
                    </template>
                    <template slot="footer">
                      <div class="legend">
                        <i class="fa fa-circle text-info"></i> Ios
                        {{ this.persen[0] }}
                        <i class="fa fa-circle text-danger"></i> Website
                        {{ this.persen[1] }}
                        <i class="fa fa-circle text-warning"></i> Android
                        {{ this.persen[2] }}
                      </div>
                    </template>
                  </chart-card>
                </div>
                <div v-else>
                  <chart-card
                    :chart-data="pieChart1.data"
                    class="text-center"
                    chart-type="Pie"
                  >
                    <template slot="header">
                      <h4 class="card-title">สรุปการดูโฆษณา</h4>
                      <p class="card-category"></p>
                    </template>
                    <template slot="footer">
                      <div class="legend">
                        <i class="fa fa-circle text-info"></i> Ios
                        <i class="fa fa-circle text-danger"></i> Website
                        <i class="fa fa-circle text-warning"></i> Android
                      </div>
                    </template>
                  </chart-card>
                </div>
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard
  },
  data() {
    return {
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2"
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      pieChart: {
        data: {
          labels: [" ", " ", " "],
          series: []
        }
      },
      pieChart1: {
        data: {
          labels: ["ไม่มีข้อมูลสรุป"],
          series: [0]
        }
      },
      isChart: false,
      result_data: [],
      accept_url: "",
      click_url: "",
      platform_name: "",
      isnull: 0,
      persen: []
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/show_plateform");
    }
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      platform_name: this.$route.query.plateform
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };
    await this.$axios
      .post("/detail_platform", { token: token }, { headers: headers })
      .then(async res => {
        if (res.data.payload && this.$route.query.plateform) {
          res.data.payload.status =
            res.data.payload.status === 1 ? "เปิดใช้งาน" : "ยกเลิกใช้งาน";
          let result_series =
            res.data.payload.device_ios +
            res.data.payload.device_web +
            res.data.payload.device_android;
          let result_labels = [];
          this.persen[0] =
            res.data.payload.device_ios > 0
              ? ((res.data.payload.device_ios * 100) / result_series).toFixed(
                  0
                ) + "%"
              : "0%";
          this.persen[1] =
            res.data.payload.device_web > 0
              ? ((res.data.payload.device_web * 100) / result_series).toFixed(
                  0
                ) + "%"
              : "0%";
          this.persen[2] =
            res.data.payload.device_android > 0
              ? (
                  (res.data.payload.device_android * 100) /
                  result_series
                ).toFixed(0) + "%"
              : "0%";

          this.pieChart.data.series[0] =
            res.data.payload.device_ios > 0
              ? (res.data.payload.device_ios * 100) / result_series
              : "";
          this.pieChart.data.series[1] =
            res.data.payload.device_web > 0
              ? (res.data.payload.device_web * 100) / result_series
              : "";
          this.pieChart.data.series[2] =
            res.data.payload.device_android > 0
              ? (res.data.payload.device_android * 100) / result_series
              : "";
          this.accept_url = res.data.payload.accept_platform.toString();
          this.click_url = res.data.payload.click_platform.toString();
          this.result_data = res.data.payload;
          this.isnull =
            this.pieChart.data.series[0].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[1].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[2].length === 0 ? (this.isnull += 1) : -1;
          this.isnull = this.isnull === 3 ? true : false;
          this.isChart = true;
        } else {
          this.$router.push("/");
        }
      });
  }
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
</style>
