<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div>
          <div class="col-sm-12">
            <template>
              <div class="row">
                <div class="col-md-2 col-lg-2 col-sm-2 col-6">
                  <img :src="img_user" alt="avatar" class="styleimg" />
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-6 text-group">
                  <a class="navbar-brand float-rigth"
                    ><p class="sit">
                      {{
                        this.$route.query.web
                          ? "เว็บไซต์ : " + this.$route.query.web
                          : null
                      }}
                    </p></a
                  >
                </div>
              </div>
              <div class="row mt-3 mb-4 mt-4">
                <div class="col-md-3 col-lg-2 col-sm-12">
                  <button
                    class="btn btn-info text-nowrap"
                    @click="handleback()"
                  >
                    <i class="fa fa-step-backward"></i> ย้อนกลับ
                  </button>
                </div>
              </div>
              <card title="รายงานสรุปแคมเปญโฆษณา">
                <div class="flex">
                  <h5 class="mt-4">เลือกแคมเปญโฆษณา</h5>
                  <!-- <mdb-select
                    v-model="select_compaign"
                    :options="select_compaign"
                    wrapperStyle="height: auto;"
                    placeholder="เลือกแคมเปญโฆษณา"
                    label=""
                    @getValue="handleselect"
                    :search="true"
      console.log(value.target.value);
      console.log(value.target.selectedOptions[0].innerText);
                  /> -->
                  <select
                    name=""
                    id=""
                    class="browser-default custom-select"
                    @change="
                      handleselect(
                        $event.target.value,
                        $event.target.selectedOptions[0].innerText
                      )
                    "
                  >
                    <option value="" selected disabled>เลือกแคมเปญโฆษณา</option>
                    <option
                      :selected="item.selected"
                      :data-selected="item.selected"
                      :value="item.value"
                      :data-text="item.text"
                      v-for="item in select_compaign"
                      :key="item.value"
                      >{{ item.text }}</option
                    ></select
                  >
                </div>
                <hr />

                <div class="row mt-5" v-if="isChart">
                  <div class="col-xl-12 col-sm-12 col-md-12 mb-4">
                    <mdb-card>
                      <mdb-card-body>
                        <mdb-card-title
                          >แคมเปญโฆษณา :
                          <b>{{ compaign_name }}</b></mdb-card-title
                        >
                        <mdb-card-text class="mt-4 mb-2">
                          <p>วันที่เริ่มแคมเปญ : {{ result_data.createdAt }}</p>
                          <p>รหัสแคมเปญ : {{ result_data.path }}</p>
                          <p>
                            สถานะแคมเปญ :
                            {{ result_data.status === 1 ? "ใช้งาน" : "ยกเลิก" }}
                          </p>
                          <p>
                            อัพเดตล่าสุด : {{ result_data.updatedAt }}
                          </p></mdb-card-text
                        >
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                  <div class="col-xl-3 col-md-3">
                    <stats-card :title="interest_url" subTitle="คนดูทั้งหมด">
                      <div slot="header" class="icon-warning">
                        <i class="nc-icon nc-align-left-2 text-warning"></i>
                      </div>
                    </stats-card>
                  </div>

                  <div class="col-xl-3 col-md-3">
                    <stats-card :title="click_url" subTitle="คนสนใจทั้งหมด">
                      <div slot="header" class="icon-success">
                        <i class="nc-icon nc-align-left-2 text-success"></i>
                      </div>
                    </stats-card>
                  </div>

                  <div class="col-xl-3 col-md-3">
                    <stats-card
                      :title="register"
                      subTitle="คนที่สมัครสมาชิกทั้งหมด"
                    >
                      <div slot="header" class="icon-danger">
                        <i class="nc-icon nc-align-left-2 text-danger"></i>
                      </div>
                    </stats-card>
                  </div>
                  <div class="col-xl-3 col-md-3">
                    <stats-card
                      :title="register_deposit"
                      subTitle="สมาชิกที่ฝากเงินทั้งหมด"
                    >
                      <div slot="header" class="icon-danger">
                        <i class="nc-icon nc-align-left-2 text-info"></i>
                      </div>
                    </stats-card>
                  </div>
                  <div class="col-xs-12 col-md-12 col-sm-12 col-xl-3">
                    <mdb-card style="height: 94.5%">
                      <h5 class="card-title mt-4 ml-3">พฤติกรรมคนที่สนใจ</h5>
                      <mdb-doughnut-chart
                        :data="doughnutChartData"
                        :options="doughnutChartOptions"
                        :width="350"
                        :height="327"
                        class="mt-5"
                      ></mdb-doughnut-chart
                    ></mdb-card>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xl-9">
                    <div class="row">
                      <div class="col-xl-4 col-md-4">
                        <stats-card
                          :title="agv_data[0]"
                          subTitle="คนที่สนใจ ( % )"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-chart-bar-32 text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                      <div class="col-xl-4 col-md-4">
                        <stats-card
                          :title="agv_data[1]"
                          subTitle="คนที่สมัคร ( % )"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-chart-bar-32 text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                      <div class="col-xl-4 col-md-4">
                        <stats-card
                          :title="agv_data[2]"
                          subTitle="สมาชิกที่ฝากเงิน ( % )"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-chart-bar-32 text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                    </div>
                    <chart-card
                      :chart-data="barChart.data"
                      :chart-options="barChart.options"
                      :chart-responsive-options="barChart.responsiveOptions"
                      chart-type="Bar"
                    >
                      <template slot="header">
                        <div class="row">
                          <div class="col-md-6 col-sm-12 col-xl-6">
                            <h5 class="card-title" style="margin-top: 10px">
                              สรุปสมัครสมาชิก {{ selectday }} ย้อนหลัง
                            </h5>
                          </div>
                          <div
                            class="col-md-3 col-sm-12 col-xl-3 text-rigth"
                          ></div>
                          <div class="col-md-3 col-sm-12 col-xl-3 text-rigth">
                            <mdb-select
                              v-model="select_day"
                              :options="select_day"
                              wrapperStyle=""
                              placeholder="ตัวเลือก"
                              label=""
                              @getValue="handleselectday"
                              style="margin-top: -25px"
                            />
                          </div>
                        </div>
                      </template>
                      <template slot="footer">
                        <div class="legend">
                          <i class="fa fa-circle text-info"></i> สมัครสมาชิก
                          <i class="fa fa-circle text-danger"></i>
                          สมาชิกที่ฝากเงินฝากครั้งแรก
                          <i class="fa fa-circle text-warning"></i>
                          สมาชิกที่ฝากเงินฝากครั้งที่ 2
                        </div>
                      </template>
                    </chart-card>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xl-12">
                    <div class="row">
                      <div class="col-xl-3 col-md-3">
                        <stats-card
                          :title="firstdeposit"
                          subTitle="เงินฝากครั้งแรก"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-money-coins text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                      <div class="col-xl-3 col-md-3">
                        <stats-card
                          :title="secondedeposit"
                          subTitle="เงินฝากครั้งที่ 2"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-money-coins text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                      <div class="col-xl-3 col-md-3">
                        <stats-card :title="sumtotal" subTitle="เงินฝากทั้งหมด">
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-money-coins text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                      <div class="col-xl-3 col-md-3">
                        <stats-card
                          :title="sumtotalwithdraw"
                          subTitle="เงินถอนทั้งหมด"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-money-coins text-success"></i>
                          </div>
                        </stats-card>
                      </div>
                      <!-- <div class="col-xl-4 col-md-4">
                        <stats-card
                          :title="sumprofit"
                          subTitle="สรุป กำไร / ขาดทุน"
                        >
                          <div slot="header" class="icon-danger">
                            <i class="nc-icon nc-money-coins text-success"></i>
                          </div>
                        </stats-card>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xl-12">
                    <div class="row">
                      <div class="col-sm-12 col-md-5">
                        <h5 class="card-title ml-2">รายละเอียดคนสมัครสมาชิก</h5>
                        <b class="ml-3" style="color: red; font-size: 13px"
                          >เริ่มเก็บข้อมูล การถอนเงินตั้งแต่ วันที่ 19 สิงหาคม
                          2564 เป็นต้นไป</b
                        >
                      </div>
                      <div class="mb-2">
                        <div class="align-self-center pl-3">
                          ข้อมูลจากวันที่
                        </div>
                        <div class="align-self-center col-sm-12 col-md-2">
                          <date-picker
                            class="input-date"
                            valueType="format"
                            lang="en"
                            v-model="date_start"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="mb-2">
                        <div class="align-self-center pl-3">
                          ข้อมูลถึงวันที่
                        </div>
                        <div class="align-self-center col-sm-12 col-md-2">
                          <date-picker
                            class="input-date"
                            valueType="format"
                            lang="en"
                            v-model="date_end"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-3 mt-2 text-center">
                        <button
                          class="text-nowrap btn btn-success"
                          type="success"
                          @click="handleFind()"
                        >
                          ค้นหา
                        </button>
                        <button
                          class="text-nowrap btn btn-info"
                          type="info"
                          @click="handlereset()"
                        >
                          ล้างข้อมูล
                        </button>
                      </div>
                    </div>
                    <mdb-datatable :data="data" striped bordered responsive />
                  </div>
                </div>
              </card>
            </template>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Pagination as LPagination,
  StatsCard,
  ChartCard
} from "src/components/index";
import {
  mdbBtn,
  mdbListGroupItem,
  mdbIcon,
  mdbSelect,
  mdbDoughnutChart,
  mdbBarChart,
  mdbCard,
  mdbDatatable,
  mdbCardImage,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText
} from "mdbvue";
import moment, { parseZone } from "moment";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
import DatePicker from "vue2-datepicker";
import { format, values } from "d3";
let no = 0;
let highBound = 0;
export default {
  components: {
    mdbBtn,
    DatePicker,
    LPagination,
    mdbListGroupItem,
    mdbIcon,
    StatsCard,
    mdbSelect,
    mdbDoughnutChart,
    mdbBarChart,
    mdbCard,
    ChartCard,
    mdbDatatable,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  },
  computed: {},
  data() {
    return {
      date_start: "",
      date_end: "",
      firstdeposit: "",
      secondedeposit: "",
      sumtotal: "",
      sumtotalwithdraw: "",
      sumtotalwithdraws: "",
      sumprofit: "",
      web: "",
      img_user: "",
      renderComponent: true,
      serviceName: null,
      date_now: "",
      result_data: "",
      isChart: false,
      selectday: "7 วัน",
      id_campaign: "",
      select_compaign: [],
      select_day: [
        { text: "7 วัน", value: "7" },
        { text: "14 วัน", value: "14" },
        { text: "30 วัน", value: "30" }
      ],
      interest_url: 0,
      click_url: 0,
      register: 0,
      register_deposit: 0,
      agv_data: [],
      compaign_name: "",
      doughnutChartData: {
        labels: ["คนสนใจ", "คนสมัครสมาชิก", "สมาชิกที่ผากเงิน"],
        datasets: [
          {
            data: [],
            backgroundColor: ["#28a745", "#F7464A", "#33b5e5"],
            hoverBackgroundColor: ["#28a745", "#F7464A", "#33b5e5"]
          }
        ]
      },
      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      barChart: {
        data: {
          labels: [],
          series: [[], [], []]
        },
        options: {
          seriesBarDistance: 12,
          axisX: {
            showGrid: false
          },
          height: "245px"
        },
        responsiveOptions: [
          [
            "screen and (max-width:100%)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      data: {
        columns: [
          {
            label: "ลำดับ",
            sort: "asc",
            format: () => {
              no += 1;
              return no;
            }
          },
          {
            label: "วันที่สมัคร",
            field: "date_regis",
            sort: "asc"
          },
          {
            label: "ยูสเซอร์เนมลูกค้า",
            field: "username",
            sort: "asc"
          },
          {
            label: "วันที่ฝากครั้งแรก",
            field: "datetime_deposit",
            sort: "asc"
          },
          {
            label: "ฝากครั้งแรก",
            field: "deposit",
            sort: "asc"
          },
          {
            label: "วันที่ฝากครั้งต่อมา",
            field: "datetime_second",
            sort: "asc"
          },
          {
            label: "ฝากครั้งต่อมา",
            field: "second_deposite",
            sort: "asc"
          },
          {
            label: "ฝากเงินทั้งหมด",
            field: "total_deposit",
            sort: "asc"
          },
          {
            label: "ถอนเงินทั้งหมด",
            field: "withdraw",
            sort: "asc"
          },
          {
            label: "กำไร",
            sort: "asc",
            field: "profit",
            format: value => {
              if (value < 0) {
                return "<b style='color: red'>" + value + "</b>";
              } else {
                return "<b style='color: green'>" + value + "</b>";
              }
            }
          }
        ],
        rows: []
      }
    };
  },
  watch: {},
  methods: {
    async handlereset() {
      this.date_start = "";
      this.date_end = "";
      no = 0;
      this.isChart = false;
      this.date_now = moment().format("DD-MM-YYYY");
      if (this.compaign_name) {
        this.barChart.data.labels = [];
        this.barChart.data.series[0] = [];
        this.barChart.data.series[1] = [];
        this.barChart.data.series[2] = [];
        let payload = {
          id: this.id_campaign
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/detail_report", { token: token }, { headers: headers })
          .then(async res => {
            if (res && res.data.code === 0) {
              this.result_data = res.data.tracking;
              let check_deposit = 0;
              let result_log = [];
              let firstdeposits = 0;
              let secondedeposits = 0;
              let sumtotals = 0;
              let sumtotalwithdraws = 0;

              for (const item of res.data.log) {
                firstdeposits +=
                  firstdeposits + item.deposit ? item.deposit : 0;
                secondedeposits +=
                  secondedeposits + item.second_deposite
                    ? item.second_deposite
                    : 0;
                sumtotals +=
                  sumtotals + item.total_deposit ? item.total_deposit : 0;
                sumtotalwithdraws +=
                  sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                item.profit = item.total_deposit - item.withdraw;
                result_log.push(item);
              }
              this.firstdeposit = parseFloat(firstdeposits)
                .toFixed(2)
                ?.toString();
              this.sumtotal =
                sumtotals > 0
                  ? parseFloat(sumtotals)
                      .toFixed(2)
                      ?.toString()
                  : "0";
              this.sumtotalwithdraw =
                sumtotalwithdraws > 0
                  ? parseFloat(sumtotalwithdraws)
                      .toFixed(2)
                      ?.toString()
                  : "0";
              this.sumprofit =
                this.sumtotal -
                this.sumtotalwithdraw +
                " ( " +
                (
                  ((this.sumtotal - this.sumtotalwithdraw) * 100) /
                  this.sumtotal
                ).toFixed(2) +
                "% )";
              this.secondedeposit = parseFloat(secondedeposits)
                .toFixed(2)
                ?.toString();
              this.data.rows = result_log;
              res.data.log.map(function(e) {
                if (e.deposit != null) {
                  check_deposit += 1;
                }
              });
              this.interest_url = res.data.tracking
                ? res.data.tracking.interest_url === 0
                  ? "-"
                  : res.data.tracking.interest_url?.toString()
                : "-";
              this.click_url = res.data.tracking
                ? res.data.tracking.click_url === 0
                  ? "-"
                  : res.data.tracking.click_url?.toString()
                : "-";
              this.register = res.data.log
                ? res.data.log.length === 0
                  ? "-"
                  : res.data.log.length?.toString()
                : "-";
              this.register_deposit = res.data.log
                ? check_deposit === 0
                  ? "-"
                  : check_deposit?.toString()
                : "-";

              this.agv_data[0] =
                (this.click_url * 100) / this.interest_url
                  ? parseFloat(
                      (this.click_url * 100) / this.interest_url
                    ).toFixed(2)
                  : "-";
              this.agv_data[1] =
                (this.register * 100) / this.click_url
                  ? parseFloat((this.register * 100) / this.click_url).toFixed(
                      2
                    )
                  : "-";
              this.agv_data[2] =
                (this.register_deposit * 100) / this.register
                  ? parseFloat(
                      (this.register_deposit * 100) / this.register
                    ).toFixed(2)
                  : "-";
              this.doughnutChartData.datasets[0].data[2] =
                this.register_deposit === "-" ? 0 : this.register_deposit;
              this.doughnutChartData.datasets[0].data[1] =
                this.register === "-" ? 0 : this.register;
              this.doughnutChartData.datasets[0].data[0] =
                parseInt(this.click_url === "-" ? 0 : this.click_url) -
                parseInt(this.doughnutChartData.datasets[0].data[1]) -
                parseInt(this.doughnutChartData.datasets[0].data[2]);
              let days = [];
              let amount_regis = [];
              for (let i = 6; i >= 0; i--) {
                let check_deposit = 0;
                let check_memberdeposit = 0;
                let check_memberdepositsecond = 0;
                res.data.log.map(function(e) {
                  if (
                    moment(e.date_regis).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD")
                  ) {
                    check_deposit += 1;
                  }

                  if (
                    moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                    e.deposit > 0
                  ) {
                    check_memberdeposit += 1;
                  }

                  if (
                    moment(e.datetime_second).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                    e.second_deposite > 0
                  ) {
                    check_memberdepositsecond += 1;
                  }
                });
                // res.data.log.map(function (e) {
                //   if (
                //     moment(e.date_regis).format("YYYY-MM-DD") ===
                //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                //     e.deposit > 0
                //   ) {
                //     check_memberdeposit += 1;
                //   }
                // });
                this.barChart.data.series[0][6 - i] = check_deposit;
                this.barChart.data.series[1][6 - i] = check_memberdeposit;
                this.barChart.data.series[2][6 - i] = check_memberdepositsecond;
                days.push(
                  moment()
                    .add(-i, "days")
                    .format("DD")
                );
              }
              this.barChart.data.labels = days;
            }
          });
        this.isChart = true;
      } else {
        this.isChart = false;
      }
    },
    async handleFind() {
      if (
        this.date_start != "" &&
        this.date_start != null &&
        this.date_end != "" &&
        this.date_end != null
      ) {
        this.date_start = moment(this.date_start).format("YYYY-MM-DD");
        this.date_end = moment(this.date_end).format("YYYY-MM-DD");
        var start = moment(this.date_start).format("YYYY-MM-DD");
        var end = moment(this.date_end).format("YYYY-MM-DD");

        if (start > end) {
          this.date_end = start;
          this.date_start = end;
        }
        no = 0;
        this.isChart = false;
        this.date_now = moment().format("DD-MM-YYYY");
        if (this.compaign_name) {
          this.barChart.data.labels = [];
          this.barChart.data.series[0] = [];
          this.barChart.data.series[1] = [];
          this.barChart.data.series[2] = [];
          let payload = {
            id: this.id_campaign,
            date_start: this.date_start,
            date_end: moment(this.date_end)
              .add(1, "days")
              .format("YYYY-MM-DD")
          };
          let user = JSON.parse(localStorage.getItem("user"));
          var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
          let headers = {
            "Content-Type": "application/json",
            Authorization: "authorization " + user
          };
          await this.$axios
            .post("/detail_report", { token: token }, { headers: headers })
            .then(async res => {
              if (res && res.data.code === 0) {
                this.result_data = res.data.tracking;
                let check_deposit = 0;
                let result_log = [];
                let firstdeposits = 0;
                let secondedeposits = 0;
                let sumtotals = 0;
                let sumtotalwithdraws = 0;

                for (const item of res.data.log) {
                  firstdeposits +=
                    firstdeposits + item.deposit ? item.deposit : 0;
                  secondedeposits +=
                    secondedeposits + item.second_deposite
                      ? item.second_deposite
                      : 0;
                  sumtotals +=
                    sumtotals + item.total_deposit ? item.total_deposit : 0;
                  sumtotalwithdraws +=
                    sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                  item.profit = item.total_deposit - item.withdraw;
                  result_log.push(item);
                }
                this.firstdeposit = parseFloat(firstdeposits)
                  .toFixed(2)
                  ?.toString();
                this.sumtotal =
                  sumtotals > 0
                    ? parseFloat(sumtotals)
                        .toFixed(2)
                        ?.toString()
                    : "0";
                this.sumtotalwithdraw =
                  sumtotalwithdraws > 0
                    ? parseFloat(sumtotalwithdraws)
                        .toFixed(2)
                        ?.toString()
                    : "0";
                this.sumprofit =
                  this.sumtotal -
                  this.sumtotalwithdraw +
                  " ( " +
                  (
                    ((this.sumtotal - this.sumtotalwithdraw) * 100) /
                    this.sumtotal
                  ).toFixed(2) +
                  "% )";
                this.secondedeposit = parseFloat(secondedeposits)
                  .toFixed(2)
                  ?.toString();
                this.data.rows = result_log;
                res.data.log.map(function(e) {
                  if (e.deposit != null) {
                    check_deposit += 1;
                  }
                });
                this.interest_url = res.data.tracking
                  ? res.data.tracking.interest_url === 0
                    ? "-"
                    : res.data.tracking.interest_url?.toString()
                  : "-";
                this.click_url = res.data.tracking
                  ? res.data.tracking.click_url === 0
                    ? "-"
                    : res.data.tracking.click_url?.toString()
                  : "-";
                this.register = res.data.log
                  ? res.data.log.length === 0
                    ? "-"
                    : res.data.log.length?.toString()
                  : "-";
                this.register_deposit = res.data.log
                  ? check_deposit === 0
                    ? "-"
                    : check_deposit?.toString()
                  : "-";

                this.agv_data[0] =
                  (this.click_url * 100) / this.interest_url
                    ? parseFloat(
                        (this.click_url * 100) / this.interest_url
                      ).toFixed(2)
                    : "-";
                this.agv_data[1] =
                  (this.register * 100) / this.click_url
                    ? parseFloat(
                        (this.register * 100) / this.click_url
                      ).toFixed(2)
                    : "-";
                this.agv_data[2] =
                  (this.register_deposit * 100) / this.register
                    ? parseFloat(
                        (this.register_deposit * 100) / this.register
                      ).toFixed(2)
                    : "-";
                this.doughnutChartData.datasets[0].data[2] =
                  this.register_deposit === "-" ? 0 : this.register_deposit;
                this.doughnutChartData.datasets[0].data[1] =
                  this.register === "-" ? 0 : this.register;
                this.doughnutChartData.datasets[0].data[0] =
                  parseInt(this.click_url === "-" ? 0 : this.click_url) -
                  parseInt(this.doughnutChartData.datasets[0].data[1]) -
                  parseInt(this.doughnutChartData.datasets[0].data[2]);
                let days = [];
                let amount_regis = [];
                for (let i = 6; i >= 0; i--) {
                  let check_deposit = 0;
                  let check_memberdeposit = 0;
                  let check_memberdepositsecond = 0;
                  res.data.log.map(function(e) {
                    if (
                      moment(e.date_regis).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD")
                    ) {
                      check_deposit += 1;
                    }

                    if (
                      moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                        moment()
                          .add(-i, "days")
                          .format("YYYY-MM-DD") &&
                      e.deposit > 0
                    ) {
                      check_memberdeposit += 1;
                    }
                    if (
                      moment(e.datetime_second).format("YYYY-MM-DD") ===
                        moment()
                          .add(-i, "days")
                          .format("YYYY-MM-DD") &&
                      e.second_deposite > 0
                    ) {
                      check_memberdepositsecond += 1;
                    }
                  });
                  // res.data.log.map(function (e) {
                  //   if (
                  //     moment(e.date_regis).format("YYYY-MM-DD") ===
                  //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                  //     e.deposit > 0
                  //   ) {
                  //     check_memberdeposit += 1;
                  //   }
                  // });
                  this.barChart.data.series[0][6 - i] = check_deposit;
                  this.barChart.data.series[1][6 - i] = check_memberdeposit;
                  this.barChart.data.series[2][
                    6 - i
                  ] = check_memberdepositsecond;
                  days.push(
                    moment()
                      .add(-i, "days")
                      .format("DD")
                  );
                }
                this.barChart.data.labels = days;
              }
            });
          this.isChart = true;
        } else {
          this.isChart = false;
        }
      }
    },
    handleback() {
      this.$router.push("/web/service?web=" + this.$route.query.web);
    },
    handleareport() {
      this.$router.push("/create_result?web=" + this.$route.query.web);
    },
    async handleselectday(value, text) {
      this.selectday = text;
      if (text) {
        this.barChart.data.labels = [];
        this.barChart.data.series[0] = [];
        this.barChart.data.series[1] = [];
        this.barChart.data.series[2] = [];
        this.isChart = false;
        let user = JSON.parse(localStorage.getItem("user"));
        let payload = {
          id: this.id_campaign,
          date_start: moment()
            .add(-value, "days")
            .format("YYYY-MM-DD"),
          date_end: moment()
            .add(1, "days")
            .format("YYYY-MM-DD")
        };
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/detail_report", { token: token }, { headers: headers })
          .then(async res => {
            if (res && res.data.code === 0) {
              this.result_data = res.data.tracking;
              let check_deposit = 0;
              let result_log = [];
              this.firstdeposit = 0;
              this.secondedeposit = 0;
              this.sumtotal = 0;
              this.sumtotalwithdraw = 0;
              let firstdeposits = 0;
              let secondedeposits = 0;
              let sumtotals = 0;
              this.sumtotalwithdraws = 0;
              for (const item of res.data.log) {
                firstdeposits +=
                  firstdeposits + item.deposit ? item.deposit : 0;
                secondedeposits +=
                  secondedeposits + item.second_deposite
                    ? item.second_deposite
                    : 0;
                sumtotals +=
                  sumtotals + item.total_deposit ? item.total_deposit : 0;
                this.sumtotalwithdraws +=
                  this.sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                item.profit = item.total_deposit - item.withdraw;
                result_log.push(item);
              }
              this.firstdeposit = parseFloat(firstdeposits)
                .toFixed(2)
                ?.toString();
              this.sumtotal =
                sumtotals > 0
                  ? parseFloat(sumtotals)
                      .toFixed(2)
                      ?.toString()
                  : "0";
              this.sumtotalwithdraw =
                this.sumtotalwithdraws > 0
                  ? parseFloat(this.sumtotalwithdraws)
                      .toFixed(2)
                      ?.toString()
                  : "0";
              this.sumprofit = "กำไร";
              this.secondedeposit = parseFloat(secondedeposits)
                .toFixed(2)
                ?.toString();
              this.data.rows = result_log;
              res.data.log.map(function(e) {
                if (e.deposit != null) {
                  check_deposit += 1;
                }
              });
              this.interest_url = res.data.tracking
                ? res.data.tracking.interest_url === 0
                  ? "-"
                  : res.data.tracking.interest_url?.toString()
                : "-";
              this.click_url = res.data.tracking
                ? res.data.tracking.click_url === 0
                  ? "-"
                  : res.data.tracking.click_url?.toString()
                : "-";
              this.register = res.data.log
                ? res.data.log.length === 0
                  ? "-"
                  : res.data.log.length?.toString()
                : "-";
              this.register_deposit = res.data.log
                ? check_deposit === 0
                  ? "-"
                  : check_deposit?.toString()
                : "-";

              this.agv_data[0] =
                (this.click_url * 100) / this.interest_url
                  ? parseFloat(
                      (this.click_url * 100) / this.interest_url
                    ).toFixed(2)
                  : "-";
              this.agv_data[1] =
                (this.register * 100) / this.click_url
                  ? parseFloat((this.register * 100) / this.click_url).toFixed(
                      2
                    )
                  : "-";
              this.agv_data[2] =
                (this.register_deposit * 100) / this.register
                  ? parseFloat(
                      (this.register_deposit * 100) / this.register
                    ).toFixed(2)
                  : "-";
              this.doughnutChartData.datasets[0].data[2] =
                this.register_deposit === "-" ? 0 : this.register_deposit;
              this.doughnutChartData.datasets[0].data[1] =
                this.register === "-" ? 0 : this.register;
              this.doughnutChartData.datasets[0].data[0] =
                parseInt(this.click_url === "-" ? 0 : this.click_url) -
                parseInt(this.doughnutChartData.datasets[0].data[1]) -
                parseInt(this.doughnutChartData.datasets[0].data[2]);
              let days = [];
              let amount_regis = [];
              for (let i = value - 1; i >= 0; i--) {
                let check_deposit = 0;
                let check_memberdeposit = 0;
                let check_memberdepositsecond = 0;
                res.data.log.map(function(e) {
                  if (
                    moment(e.date_regis).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD")
                  ) {
                    check_deposit += 1;
                  }

                  if (
                    moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                    e.deposit > 0
                  ) {
                    check_memberdeposit += 1;
                  }

                  if (
                    moment(e.datetime_second).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                    e.second_deposite > 0
                  ) {
                    check_memberdepositsecond += 1;
                  }
                });
                // res.data.log.map(function (e) {
                //   if (
                //     moment(e.date_regis).format("YYYY-MM-DD") ===
                //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                //     e.deposit > 0
                //   ) {
                //     check_memberdeposit += 1;
                //   }
                // });
                this.barChart.data.series[0][value - 1 - i] = check_deposit;
                this.barChart.data.series[1][
                  value - 1 - i
                ] = check_memberdeposit;
                this.barChart.data.series[2][6 - i] = check_memberdepositsecond;
                days.push(
                  moment()
                    .add(-i, "days")
                    .format("DD")
                );
              }
              this.barChart.data.labels = days;
            }
          });
        this.isChart = true;
      } else {
        this.isChart = false;
      }
    },
    async handleselect(value, text) {
      no = 0;
      this.isChart = false;
      this.date_now = moment().format("DD-MM-YYYY");
      if (text) {
        this.barChart.data.labels = [];
        this.barChart.data.series[0] = [];
        this.barChart.data.series[1] = [];
        this.barChart.data.series[2] = [];
        this.id_campaign = value;
        this.compaign_name = text;
        let payload = {
          id: value
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/detail_report", { token: token }, { headers: headers })
          .then(async res => {
            if (res && res.data.code === 0) {
              this.result_data = res.data.tracking;
              let check_deposit = 0;
              let result_log = [];
              let firstdeposits = 0;
              let secondedeposits = 0;
              let sumtotals = 0;
              let sumtotalwithdraws = 0;

              for (const item of res.data.log) {
                firstdeposits +=
                  firstdeposits + item.deposit ? item.deposit : 0;
                secondedeposits +=
                  secondedeposits + item.second_deposite
                    ? item.second_deposite
                    : 0;
                sumtotals +=
                  sumtotals + item.total_deposit ? item.total_deposit : 0;
                sumtotalwithdraws +=
                  sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                item.profit = item.total_deposit - item.withdraw;
                result_log.push(item);
              }
              this.firstdeposit = parseFloat(firstdeposits)
                .toFixed(2)
                ?.toString();
              this.sumtotal =
                sumtotals > 0
                  ? parseFloat(sumtotals)
                      .toFixed(2)
                      ?.toString()
                  : "0";
              this.sumtotalwithdraw =
                sumtotalwithdraws > 0
                  ? parseFloat(sumtotalwithdraws)
                      .toFixed(2)
                      ?.toString()
                  : "0";
              this.sumprofit =
                this.sumtotal -
                this.sumtotalwithdraw +
                " ( " +
                (
                  ((this.sumtotal - this.sumtotalwithdraw) * 100) /
                  this.sumtotal
                ).toFixed(2) +
                "% )";
              this.secondedeposit = parseFloat(secondedeposits)
                .toFixed(2)
                ?.toString();
              this.data.rows = result_log;
              res.data.log.map(function(e) {
                if (e.deposit != null) {
                  check_deposit += 1;
                }
              });
              this.interest_url = res.data.tracking
                ? res.data.tracking.interest_url === 0
                  ? "-"
                  : res.data.tracking.interest_url?.toString()
                : "-";
              this.click_url = res.data.tracking
                ? res.data.tracking.click_url === 0
                  ? "-"
                  : res.data.tracking.click_url?.toString()
                : "-";
              this.register = res.data.log
                ? res.data.log.length === 0
                  ? "-"
                  : res.data.log.length?.toString()
                : "-";
              this.register_deposit = res.data.log
                ? check_deposit === 0
                  ? "-"
                  : check_deposit?.toString()
                : "-";

              this.agv_data[0] =
                (this.click_url * 100) / this.interest_url
                  ? parseFloat(
                      (this.click_url * 100) / this.interest_url
                    ).toFixed(2)
                  : "-";
              this.agv_data[1] =
                (this.register * 100) / this.click_url
                  ? parseFloat((this.register * 100) / this.click_url).toFixed(
                      2
                    )
                  : "-";
              this.agv_data[2] =
                (this.register_deposit * 100) / this.register
                  ? parseFloat(
                      (this.register_deposit * 100) / this.register
                    ).toFixed(2)
                  : "-";
              this.doughnutChartData.datasets[0].data[2] =
                this.register_deposit === "-" ? 0 : this.register_deposit;
              this.doughnutChartData.datasets[0].data[1] =
                this.register === "-" ? 0 : this.register;
              this.doughnutChartData.datasets[0].data[0] =
                parseInt(this.click_url === "-" ? 0 : this.click_url) -
                parseInt(this.doughnutChartData.datasets[0].data[1]) -
                parseInt(this.doughnutChartData.datasets[0].data[2]);
              let days = [];
              let amount_regis = [];
              for (let i = 6; i >= 0; i--) {
                let check_deposit = 0;
                let check_memberdeposit = 0;
                let check_memberdepositsecond = 0;
                res.data.log.map(function(e) {
                  if (
                    moment(e.date_regis).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD")
                  ) {
                    check_deposit += 1;
                  }

                  if (
                    moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                    e.deposit > 0
                  ) {
                    check_memberdeposit += 1;
                  }

                  if (
                    moment(e.datetime_second).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                    e.second_deposite > 0
                  ) {
                    check_memberdepositsecond += 1;
                  }
                });
                // res.data.log.map(function (e) {
                //   if (
                //     moment(e.date_regis).format("YYYY-MM-DD") ===
                //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                //     e.deposit > 0
                //   ) {
                //     check_memberdeposit += 1;
                //   }
                // });
                this.barChart.data.series[0][6 - i] = check_deposit;
                this.barChart.data.series[1][6 - i] = check_memberdeposit;
                this.barChart.data.series[2][6 - i] = check_memberdepositsecond;
                days.push(
                  moment()
                    .add(-i, "days")
                    .format("DD")
                );
              }
              this.barChart.data.labels = days;
            }
          });
        this.isChart = true;
      } else {
        this.isChart = false;
      }
    }
  },
  async mounted() {
    this.date_now = moment().format("DD-MM-YYYY");
    this.img_user = "../static/img/" + this.$route.query.web + ".png";
    this.web = this.$route.query.web;
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      web: this.$route.query.web
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };
    await this.$axios
      .post("/list_tracking", { token: token }, { headers: headers })
      .then(async res => {
        let result_data = [];
        if (res.data.code === 0) {
          let optoin = [];
          for (const [index, item] of res.data.payload.entries()) {
            optoin.push({
              text: res.data.payload[index].main_campaign?.toString(),
              value: res.data.payload[index].id?.toString()
            });
          }
          this.select_compaign = optoin;
        } else {
          this.users = [];
          this.tableData = [];
        }
      });
  }
};
</script>
<style>
.custom-select-sm {
  height: calc(2em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.row-center {
  display: -webkit-inline-box;
}
.btn-action {
  padding: 8px;
  margin-right: 5px;
}
.mdb-datatable-header-hover-icon {
  display: none;
  left: 0.1rem !important;
  top: 1.25rem !important;
}
.fas {
  font-weight: 900 !important;
  font-family: Font awesome\5 Free !important;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-up:before {
  color: black !important;
  display: table-column-group !important;
}
.fa-arrow-down:before {
  color: black !important;
  display: table-column-group !important;
}
.sorting {
  cursor: pointer !important;
  font-size: 15px !important;
  color: #000 !important;
}
.el-table thead {
  color: #333333;
  font-weight: 500;
}
.el-input {
  width: 200px !important;
  max-width: 200px;
}
.styleimg {
  width: 110px;
}
.sit {
  display: flex;
  margin-left: 15px;
  color: black;
  font-weight: 600;
}
.text-group {
  margin-left: -61px;
  padding-top: 30px;
}
.form-control {
  display: inline-block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  vertical-align: middle !important;
  background: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
.select-dropdown {
  margin-top: 27px !important;
}
/* .fxh {
  margin-top: 33px !important;
  padding-left: 50px !important;
} */
</style>
