<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">อัพเดตรายละเอียดการโฆษณา</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-2">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="result_data.datetime"
                  disabled
                />
              </div>
              <div class="col-md-2">
                <fg-input
                  label="พนักงานรับผิดชอบ"
                  type="text"
                  :value="result_data.employee"
                  disabled
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  label="รหัสแคมเปญ"
                  type="text"
                  :value="result_data.path"
                  disabled
                />
              </div>
              <div class="col-md-2">
                <fg-input
                  label="ช่องทางโฆษณา"
                  type="text"
                  :value="channel"
                  disabled
                />
              </div>
              <div class="col-md-2">
                <label> แพลตฟอร์ม</label><br />
                <el-select
                  label="แพลตฟอร์ม"
                  class="select-primary"
                  size="large"
                  :placeholder="platform_name"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <fg-input
                  label="แคมเปญหลัก"
                  type="text"
                  v-model="result_data.main_campaign"
                />
              </div>
              <div class="col-md-6">
                <fg-input
                  label="แคมเปญย่อย"
                  type="text"
                  v-model="result_data.second_campaign"
                ></fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ลิ้งค์การตลาด"
                  type="text"
                  v-model="result_data.full_url"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ลิ้งค์แคมเปญ"
                  type="text"
                  :value="result_data.short_url"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="หมายเหตุ"
                  type="text"
                  v-model="result_data.comment"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <l-button type="success" wide @click="handleSave()"
                  >แก้ไขข้อมูล</l-button
                >&nbsp;
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
  CheckboxGroup
} from "element-ui";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard,
    [Option.name]: Option,
    [Select.name]: Select
  },
  data() {
    return {
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2"
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      pieChart: {
        data: {
          labels: [],
          series: []
        }
      },
      isChart: false,
      platform_name: "",
      result_data: [],
      accept_url: "",
      click_url: "",
      selects: { simple: "", countries: [] },
      channel: ""
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/redirect?web=" + this.$route.query.web);
    },
    async handleSave() {
      if (
        this.result_data.main_campaign &&
        this.result_data.second_campaign &&
        this.result_data.full_url
      ) {
        let selects = this.selects.simple
          ? this.selects.simple
          : this.result_data.platform;

        let payload = {
          id: this.$route.query.id,
          main_campaign: this.result_data.main_campaign,
          second_campaign: this.result_data.second_campaign,
          platform: selects,
          full_url: this.result_data.full_url,
          comment: this.result_data.comment
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/update_tracking", { token: token }, { headers: headers })
          .then(res => {
            if (res.data.code === 0) {
              Swal.fire({
                title: res.data.msg,
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            } else {
              Swal.fire({
                title: res.data.msg,
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            }
          })
          .catch(() => {});
      } else {
        Swal.fire({
          title: "กรอกข้อมูลให้ครบ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true
        });
      }
    }
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: this.$route.query.id
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };
    await this.$axios
      .post("/detail_tracking", { token: token }, { headers: headers })
      .then(async res => {
        if (
          res.data.payload &&
          res.data.payload.Web.service_name === this.$route.query.web
        ) {
          let result_series =
            res.data.payload.device_ios +
            res.data.payload.device_web +
            res.data.payload.device_android;
          let result_labels = [];
          this.pieChart.data.labels[0] =
            (res.data.payload.device_ios * 100) / result_series > 0
              ? ((res.data.payload.device_ios * 100) / result_series).toFixed(
                  2
                ) + "%"
              : "";
          this.pieChart.data.labels[1] =
            (res.data.payload.device_web * 100) / result_series > 0
              ? ((res.data.payload.device_web * 100) / result_series).toFixed(
                  2
                ) + "%"
              : "";
          this.pieChart.data.labels[2] =
            (res.data.payload.device_android * 100) / result_series > 0
              ? (
                  (res.data.payload.device_android * 100) /
                  result_series
                ).toFixed(2) + "%"
              : "";

          this.pieChart.data.series[0] =
            (res.data.payload.device_ios * 100) / result_series > 0
              ? (res.data.payload.device_ios * 100) / result_series
              : this.pieChart.data.series.splice(0, 1);
          this.pieChart.data.series[1] =
            (res.data.payload.device_web * 100) / result_series > 0
              ? (res.data.payload.device_web * 100) / result_series
              : this.pieChart.data.series.splice(1, 1);
          this.pieChart.data.series[2] =
            (res.data.payload.device_android * 100) / result_series > 0
              ? (res.data.payload.device_android * 100) / result_series
              : this.pieChart.data.series.splice(2, 1);
          this.isChart = true;
          this.accept_url = res.data.payload.accept_url.toString();
          this.click_url = res.data.payload.click_url.toString();
          this.platform_name = res.data.payload.Platform.platform_name;
          this.result_data = res.data.payload;
          this.channel =
            parseInt(res.data.payload.channel) === 0 ? "Line" : "Website";
        } else {
          this.$router.push("/");
        }
        let user = JSON.parse(localStorage.getItem("user"));
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .get("/list_platform", { headers: headers })
          .then(async res => {
            let selected = [];
            let select = [];
            if (res.data.code === 0) {
              await res.data.payload.filter(word => {
                if (word.id === parseInt(this.result_data.platform)) {
                  selected.push({
                    label: word.platform_name,
                    value: word.id,
                    selected: true
                  });
                  return word;
                } else {
                  select.push({
                    label: word.platform_name,
                    value: word.id
                  });
                }
              });
              this.selects.countries = selected.concat(select);
              this.showModal = true;
            } else {
              this.showModal = false;
              Swal.fire({
                text: "เกิดข้อผิดพลาด กรุณาลองใหม่",
                icon: "error",
                confirmButtonText: "ปิดหน้าต่าง"
              });
            }
          });
      });
  }
};
</script>
<style>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}
.el-select {
  position: relative !important;
  display: contents !important;
}
</style>
