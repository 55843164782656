<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div>
          <div class="col-sm-12">
            <template>
              <div class="row">
                <div class="col-md-3 col-lg-2 col-sm-12">
                  <l-button
                    class="mb-3 w-100 text-nowrap"
                    type="primary"
                    @click="handleadd()"
                    ><i class="fa fa-plus-circle"></i> เพิ่มสมาชิก</l-button
                  >
                </div>
              </div>
              <card title="ข้อมูลสมาชิกทั้งหมด">
                <div>
                  <div
                    class="
                      col-12
                      d-flex
                      justify-content-center justify-content-sm-between
                      flex-wrap
                    "
                  >
                    <el-select
                      class="select-default mb-3"
                      style="width: 200px"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-default"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <el-input
                      type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="ค้นหาสมาชิก"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    />
                  </div>
                  <div class="col-sm-12">
                    <el-table
                      class="table-striped"
                      style="width: 100%"
                      :data="queriedData"
                    >
                      <el-table-column :min-width="95" label="โปรไฟล์">
                        <template slot-scope="props">
                          <img
                            class="photo"
                            :src="props.row.Line_image"
                            alt="avatar"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="200" label="">
                        <template slot-scope="props">
                          <h5>{{ props.row.Name }}</h5>
                          <small
                            ><b>last login : </b
                            >{{ props.row.LastLogin }}</small
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                      >
                      </el-table-column>
                      <el-table-column :min-width="175" label="เพิ่มเติม">
                        <template slot-scope="props">
                          <button
                            class="btn btn-info mr-2"
                            style="padding: 8px"
                            @click="handleView(props.$index, props.row)"
                          >
                            รายละเอียด
                          </button>
                          <button
                            class="btn btn-success mr-2"
                            style="padding: 8px"
                            @click="handleEditData(props.$index, props.row)"
                          >
                            แก้ไขข้อมูล
                          </button>
                          <button
                            class="mr-2 btn btn-danger text-nowrap"
                            style="padding: 8px; width: 78px"
                            @click="handleEdit(props.$index, props.row)"
                          >
                            ลบข้อมูล
                          </button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div
                  slot="footer"
                  class="
                    col-12
                    d-flex
                    justify-content-center justify-content-sm-between
                    flex-wrap
                  "
                >
                  <div class="">
                    <p class="card-category">
                      แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ
                    </p>
                  </div>
                  <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                  >
                  </l-pagination>
                </div>
              </card>
            </template>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { mdbBtn, mdbListGroupItem, mdbIcon } from "mdbvue";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
let no = 0;

export default {
  components: {
    mdbBtn,
    LPagination,
    mdbListGroupItem,
    mdbIcon,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result_selected = [];
      if (this.searchQuery !== "") {
        let result = this.tableData;
        result = this.fuseSearch.search(this.searchQuery);
        result.map((entry) => {
          result_selected.push(entry.item);
        });
        this.tableData = result_selected;
        this.pagination.total = result.length;
        return result_selected.slice(this.from, this.to);
      } else {
        return this.users.slice(this.from, this.to);
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.users.length;
    },
  },
  data() {
    return {
      web: "",
      renderComponent: true,
      serviceName: null,
      search: "",
      activeItem: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100],
        total: 0,
      },
      users: [],
      searchQuery: "",
      propsToSearch: ["Name"],
      tableColumns: [
        {
          prop: "DeptName",
          label: "ตำแหน่ง",
          minWidth: 150,
        },
        {
          prop: "IsActive",
          label: "สถานะ",
          minWidth: 150,
        },
      ],
      tableData: [],
      fuseSearch: [],
    };
  },
  methods: {
    handleView(index, row) {
      this.$router.push("/detail_member?&id=" + row.ObjectID);
    },
    handleEditData(index, row) {
      this.$router.push("/edit_member?&id=" + row.ObjectID);
    },
    handleEdit(index, row) {
      Swal.fire({
        title: "ยืนยันการลบข้อมูล",
        text: "คุณต้องการลบข้อมูล สมาชิก : ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          Swal.fire("Poof! Your imaginary file has been deleted!", {
            icon: "success",
          });
        } else {
          Swal.fire("Your imaginary file is safe!");
        }
      });
      Swal.fire({
        title: "ยืนยันการลบข้อมูล?",
        text: "ต้องการลบข้อมูลสมาชิก : " + row.Name + " หรือไม่",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบข้อมูล",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let user = JSON.parse(localStorage.getItem("user"));
          let payload = {
            ObjectID: row.ObjectID,
          };
          var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
          let headers = {
            "Content-Type": "application/json",
            Authorization: "authorization " + user,
          };
          await this.$axios
            .post(
              "/delete_member",
              {
                token: token,
              },
              { headers: headers }
            )
            .then(async (res) => {
              let result_data = [];
              if (res.data.code == 0) {
                Swal.fire("" + res.data.msg).then(async (res) => {
                  location.reload();
                });
              } else {
                Swal.fire("" + res.data.msg).then(async (res) => {
                  location.reload();
                });
              }
            });
        }
      });
    },
    handleadd() {
      this.$router.push("/create_member");
    },
  },
  async mounted() {
    this.web = this.$route.query.web;
    let user = JSON.parse(localStorage.getItem("user"));
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user,
    };
    await this.$axios
      .get("/member_list", { headers: headers })
      .then(async (res) => {
        let result_data = [];
        if (res.data.payload[0]) {
          for (const [index, item] of res.data.payload.entries()) {
            res.data.payload[index].IsActive =
              res.data.payload[index].IsActive === 1 ? "ONLINE" : "OFFLINE";
            res.data.payload[index].Line_image = res.data.payload[index]
              .Line_image
              ? res.data.payload[index].Line_image
              : "../static/img/faces/face-0.jpg";
            res.data.payload[index].LastLogin = res.data.payload[index]
              .LastLogin
              ? res.data.payload[index].LastLogin
              : "-";
          }
          this.users = res.data.payload;
          this.tableData = res.data.payload;
        } else {
          this.users = [];
          this.tableData = [];
        }
      });
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["Name"],
    });
  },
};
</script>
<style>
.row-center {
  display: -webkit-inline-box;
}
.btn-action {
  padding: 8px;
  margin-right: 5px;
}
.mdb-datatable-header-hover-icon {
  display: none;
  left: 0.1rem !important;
  top: 1.25rem !important;
}
.fas {
  font-weight: 900 !important;
  font-family: Font awesome\5 Free !important;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-up:before {
  color: black !important;
  display: table-column-group !important;
}
.fa-arrow-down:before {
  color: black !important;
  display: table-column-group !important;
}
.sorting {
  cursor: pointer !important;
  font-size: 15px !important;
  color: #000 !important;
}
.el-table thead {
  color: #333333;
  font-weight: 500;
}
.el-input {
  width: 200px !important;
  max-width: 200px;
}
.photo {
  width: 45px;
  margin-left: -5px !important;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin-right: 11px;
  margin-left: 23px;
  z-index: 5;
  border: 2px solid rgba(255, 255, 255, 0.3);
}
</style>
